.App {
  text-align: center;
  font-family: 'Inter', sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-background {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: inherit;
  display: inline-block;
  opacity: 0.25;
  max-width: unset;
  z-index: -10
}

.hero-background {
  z-index: -1;
  width: 100%;
  opacity: .05;
  object-position: 50% 50%;
  background-image: linear-gradient(rgba(255, 255, 255, 0) 70%, #f5f5f5), url("https://uploads-ssl.webflow.com/640239c900321139b18547b5/644c0acbb515178e883090e6_rf-background-graph-12.svg");
  background-position: 0 0, 0 0;
  background-size: auto, auto;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}